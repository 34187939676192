.login-form p {
    margin: 1em 0;
    text-align: center;
}
.login-form button {
    width: 6rem;
}

/*.reply-form {*/
/*    display: flex;*/
/*}*/
.reply-sticky {
    position: sticky;
    bottom: 0;
}

.reply-form textarea {
    resize: vertical;
    width: 100%;
    min-height: 2em;
    height: 4em;
}

/*.reply-form button {*/
/*    flex: 0 0 3em;*/
/*    margin-right: 0;*/
/*}*/

.reply-preview {
    width: 100%;
    min-height: 2em;
}


.post-form-bar {
    line-height: 2em;
    display: flex;
    margin-bottom: .5em;
}

.post-form-bar label {
    flex: 1;
}

.post-form-bar input[type=file] {
    border: 0;
    padding: 0 0 0 .5em;
}

@media screen and (max-width: 580px) {
    .post-form-bar input[type=file] {
        width: 120px;
    }
}

@media screen and (max-width: 320px) {
    .post-form-bar input[type=file] {
        width: 100px;
    }
}

.post-form-bar button {
    flex: 0 0 6em;
    margin-right: 0;
}

@media screen and (max-width: 580px) {
    .post-form-bar button {
        flex: 0 0 4.5em;
        margin-right: 0;
    }
}

.post-form-img-tip {
    font-size: small;
    margin-top: -.5em;
    margin-bottom: .5em;
}

.post-form textarea {
    resize: vertical;
    width: 100%;
    min-height: 5em;
    height: 20em;
}

.post-preview {
    width: 100%;
    min-height: 5em;
}

input[type="file"] {
    display: none;
}

.post-upload {
    padding: .3em .5em;
    cursor: pointer;
    opacity: 1;
    /*background-color: #fcfcfc;*/
    box-shadow: 0 0 3px rgba(0,0,0,.5);
    border-radius: 1em;
    color: var(--var-link-color);
}

.selectCss{
    background-color: rgba(255, 255, 255, 0);
    color: rgb(0, 0, 0);
    padding: 5px;
    margin-bottom: 10px;
    text-align: center;
    text-align-last: center;
}

.root-dark-mode .selectCss{
    background-color: #333;
    color: white;
    padding: 5px;
    margin-bottom: 10px;
    text-align: center;
    text-align-last: center;
}

.selectOption{
    background-color: rgba(255, 255, 255, 0);
}

.root-dark-mode .selectOption{
    background-color: #333;
}
